angular.module("app")
    .controller("travelPolicyEditController", ["$scope", "$log", "$state", "$stateParams", "$uibModal", "Notification", "travelPolicyService", function ($scope, $log, $state, $stateParams, $uibModal, Notification, travelPolicyService) {
        var vm = this;
        vm.loading = false;
        vm.loadingForSave = false;
        vm.dayOfWeek = ['Any', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        vm.tripReasonMode = ['OPTIONAL', 'REQUIRED', 'NOT_REQUIRED'];
        vm.paymentMethodMode = ['DEFAULT', 'REQUIRE_SELECTION'];
        vm.complianceMode = ['AUDIT', 'WARN', 'DISALLOW'];
        vm.timePicker = {
            hourStep: 1,
            minStep: 15,
            isMeridian: false
        };

        var travelPolicyReference = $stateParams.reference;
        vm.travelPolicyReference = travelPolicyReference;
        vm.travelPolicy = undefined;

        (function init() {
            $log.info("initialise with reference id %o", vm.travelPolicyReference);
            bindLoaderStateEvents();
            loadTravelPolicy();
        }());

        vm.dayInOrder = function (day) {
            return travelPolicyService.dayOfWeek(day.dayOfWeek);
        };

        vm.editTravelPolicy = function (travelPolicy) {
            return travelPolicyService.updateTravelPolicy(travelPolicy)
                .then(function (data) {
                    $state.go("travel-policies.view", {'reference': data.travelPolicyReference});
                });
        };

        vm.removeTravelPolicy = function (travelPolicyReference) {
            modalConfirm().then(function yes() {
                return travelPolicyService.removeTravelPolicy(travelPolicyReference)
                    .then(function (data) {
                        $state.go("travel-policies");
                    });
            });
        };

        vm.addPolicyDetail = function () {
            var startTime = new Date();
            startTime.setHours(9);
            startTime.setMinutes(0);

            var endTime = new Date();
            endTime.setHours(17);
            endTime.setMinutes(0);
            var defaultDetail = {
                dayOfWeek: 'Any',
                startDateTime: startTime,
                startTime: dateToLocalString(startTime),
                endDateTime: endTime,
                endTime: dateToLocalString(endTime),
                limitAmount: undefined,
                tripReasonMode: 'NOT_REQUIRED'
            };

            vm.travelPolicy.travelPolicyDetails.push(defaultDetail);
        };

        vm.removePolicyDetail = function (detailId) {
            var details = vm.travelPolicy.travelPolicyDetails;
            for (var i = 0; i < details.length; i++) {
                if (details[i].travelPolicyDetailReference == detailId) {
                    vm.travelPolicy.travelPolicyDetails.splice(i, 1);
                }
            }
        };

        vm.changeStartTime = function (policyDetail) {
            policyDetail.startTime = dateToLocalString(policyDetail.startDateTime);
        };

        vm.changeEndTime = function (policyDetail) {
            policyDetail.endTime = dateToLocalString(policyDetail.endDateTime);
        };

        function dateToLocalString(fullDate) {
            var timeString = fullDate.toLocaleTimeString([], {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit'
            });
            return timeString;
        }

        function modalConfirm() {
            return $uibModal.open({
                templateUrl: '/static/templates/travel-policy-removal.html',
                resolve: {
                    message: function () {
                        return 'This policy may be associated with payment methods or staffs.  Are  you sure you want to continue?';
                    }
                },
                controller: 'travelPolicyRemovalModalController as ctrl'
            }).result;
        }

        function loadTravelPolicy() {
            vm.loading = true;
            return travelPolicyService.get(travelPolicyReference)
                .then(function (data) {
                    vm.travelPolicy = data;
                    _.forEach(vm.travelPolicy.travelPolicyDetails, function (policyDetail) {
                        policyDetail.startDateTime = dateFromString(policyDetail.startTime);
                        policyDetail.endDateTime = dateFromString(policyDetail.endTime);
                        policyDetail.order = travelPolicyService.dayOfWeek(policyDetail.dayOfWeek);
                    });
                })['finally'](function () {
                vm.loading = false;
                vm.pageNotReady = false;
            });
        }

        function dateFromString(time) {
            if (time) {
                var date = new Date();
                var hourMinute = time.split(':');
                date.setHours(hourMinute[0]);
                date.setMinutes(hourMinute[1]);
                return date;
            }
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });
        }
    }])
;